<template>
  <div>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.11/cropper.css">
    <div v-show="imageSrc" class="my-2 w-64 h-64 object-fill mx-auto">
      <img class="block max-w-full" ref="img" :src="imageSrc" />
    </div>
    <div class="flex justify-center content-end mt-2">
      <p class="mt-2 text-center"
         v-if="!imageSrc"
         @click="imageInput.click()"
      >
        <BIcon icon="cloud-arrow-up"></BIcon>
        <b>{{ $t("buttons.choose_photo") + ' (jpeg, png, 5MB)' }}</b>
      </p>

      <button v-else class="btn btn-blue w-32 mx-2" @click="handleImageCropped">
          {{ $t("auth.select_area") }}
      </button>
      <button
        button
        v-if="imageSrc"
        class="btn btn-gray w-32 mx-2"
        @click="fileCleared"
      >
        {{ $t("auth.cancel") }}
      </button>
      <input
        type="file"
        ref="imageInput"
        accept=".jpg,.jpeg,.png"
        @change="fileChanged"
        :style="{ display: 'none' }"
      />
    </div>
    <div v-if="selectedFile" class="my-2 align-baseline text-center">
      <span>{{ $t("auth.selected_file") }} </span>
      <span>{{ selectedFile.name }}</span>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  watchEffect,
  onMounted,
  onUnmounted,
  watch,
} from 'vue';
import Cropper from 'cropperjs';

export default defineComponent({
  name: 'ImageCropper',
  emits: ['imageCropped'],
  setup(_props, { emit }) {
    const imageInput = ref(null);
    const selectedFile = ref(null);
    const imageSrc = ref(null);
    const img = ref(null);
    const fileReader = new FileReader();
    let cropper = null;

    fileReader.onload = (event) => {
      imageSrc.value = event.target.result;
    };

    const handleImageCropped = () => {
      cropper
        .getCroppedCanvas({
          width: 256,
          height: 256,
        })
        .toBlob((blob) => {
          emit('imageCropped', blob);
        }, 'image/jpeg');

      selectedFile.value = null;
    };

    const fileChanged = (e) => {
      const files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        selectedFile.value = files[0];
      }
    };

    const fileCleared = (_) => {
      selectedFile.value = null;
    };

    onMounted(() => {
      cropper = new Cropper(img.value, {
        viewMode: 3,
        dragMode: 'move',
        autoCrop: true,
        highlight: true,
        background: false,
        cropBoxMovable: true,
        cropBoxResizable: true,
      });
    });

    onUnmounted(() => {
      cropper.destroy();
    });

    watchEffect(() => {
      if (selectedFile.value) {
        fileReader.readAsDataURL(selectedFile.value);
      } else {
        imageSrc.value = null;
      }
    });

    watch(
      imageSrc,
      () => {
        if (imageSrc.value) {
          cropper.replace(imageSrc.value);
        }
      },
      {
        flush: 'post',
      }
    );

    return {
      imageInput,
      selectedFile,
      fileChanged,
      fileCleared,
      imageSrc,
      img,
      handleImageCropped,
    };
  },
});
</script>
