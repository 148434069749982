export default {
    en: {
        buttons: {
            cancel: 'Cancel',
            nix: 'Cancel',
            confirm: 'Save',
            yes: 'Yes',
            no: 'No',
            warning: 'Warning',
            delete: 'Remove',
            clear: 'Delete',
            yes_delete: 'Yes, delete',
            add: 'Create',
            all_students: 'All students',
            to_all_students: 'To all students',
            choose_students: 'Choose students',
            students: 'Students',
            edit: 'Edit',
            added: 'Created',
            published: 'Published',
            save: 'Save',
            post: 'Post',
            post_changes: 'Post changes',
            detail: 'Detail',
            date: 'Date:',
            time: 'Time:',
            send: 'Send',
            choose_file: 'Choose a file',
            enter: 'Press Enter to send message',
            file: 'File',
            loading: 'Loading...',
            photo_edit: 'Edit Photo',
            choose_photo: 'Choose photo',
            close: 'Close',
            create: 'Add'
        },
        message: {
            successfully_changed: 'successfully changed',
            successfully_deleted: 'successfully deleted',
            successfully_updated: 'successfully updated',
            no_notification: 'There are no new notifications.',
            empty_chat: 'There are no messages.',
            save_data: 'Data saved!',
            save_photo: 'Your photo has been changed!',
            pass_changed: 'Your password has been successfully changed!',
            answer_send: 'Your answer has been sent, please wait for verification)'
        },
        errors: {
            empty_name: 'Name not filled',
            empty_students: 'No student selected',
            empty_video: 'No youtube link added',
            empty_course: 'No course selected',
            incorrect_video: 'Incorrect youtube link',
            empty_desc: 'Description not filled',
            big_file: 'Upload a smaller file (5 MB or less).',
            incorrect_format: 'Upload the document in a valid format'
        },
        auth: {
            authorization: 'Authorization',
            password: 'Password',
            forgot_password: 'Forgot your password?',
            registration: 'Registration',
            enter: 'Enter',
            entering: 'Login',
            email: 'E-mail',
            password_warning: 'has 8 or more characters, has at least one capital letter, has at least\n' +
                'one lowercase letter, has at least one number',
            password_confirmation: 'Confirm password',
            name: 'First Name',
            last_name: 'Last Name',
            phone: 'Phone',
            login: 'Already have an account? Sign in',
            register: 'Register',
            email_confirm: 'Email confirmation',
            resend: 'Send the letter again',
            go_to_login: 'Go to login',
            check: 'Checking...',
            resended: 'Verification letter resent',
            thanks: 'Thank you for confirming your email address.',
            password_restore: 'Password recovery',
            new_password: 'New password',
            password_changed: 'Password changed successfully!',
            password_change: 'Change password',
            password_forgot: 'Forgot your password?',
            will_send: 'A message will be sent to your mail with a link to change\n' +
                'password',
            already_send: 'We have sent your password reset link via email\n' +
                'by mail!',
            back_to_login: 'Back to login',
            send: 'Send a letter',
            confirm_email: 'Confirm Email',
            please_confirm_email: 'Please confirm your email address. For this\n' +
                ' go to the link from the letter that was sent to you in the mail.',
            birthday: 'Date of birth',
            phone_number: 'Phone number',
            email_full: 'Email',
            city: 'City',
            university: 'University',
            pass_year: 'Pass year',
            old_password: 'Old password',
            confirm_new_pass: 'Confirm new password',
            select_area: 'Select area',
            cancel: 'Cancel',
            selected_file: 'Selected File:',
        },
        index : {
            null_welcome: 'You have successfully logged into the system!\nWait, soon you will be granted access!',
            student_welcome: 'Welcome, student. The course will start soon, expect a connection from your teacher)',
            student_welcome_course: 'You have been enrolled in the course ',
            lecturer_welcome: 'Welcome the teacher! Create your first course)',
            find_by_name: 'Enter username to search',
            find: 'Find',
            users: 'Users',
            id: 'ID',
            name: 'My name',
            last_name: 'Last Name',
            email: 'E-mail address',
            role: 'Role',
            operation: 'Operations',
            filter: 'Filter',
            all: 'All',
            delete_user: 'Remove this user?',
            user_role: 'User Role',
            user_delete: 'User deleted successfully',
            news: 'News',
            notification: 'Notification',
            menu: 'Menu'
        },
        courses: {
            create_course: 'Create Course',
            course_name: 'Course Name',
            add_users: 'Add members',
            course: 'Course',
            added: 'added successfully',
            created: 'Created:',
            users: 'Participants:',
            courses: 'Courses',
            delete_course: 'Delete Course',
            lecturer: 'Teacher',
            add_students: 'Add student',
            student_name: 'Enter student name',
            sure_delete_course: 'Delete this course?',
            delete_student: 'Remove this student from the course?'
        },
        lectures: {
            lectures: 'Lectures',
            add_lecture: 'Create lecture',
            lecture_name: 'Lecture Name',
            lecture_desc: 'Description (optional)',
            url: 'Insert Video URL',
            preview: 'Preview',
            add_video: 'Add video',
            delete_lecture: 'Are you sure you want to delete the lecture?',
            lecture: 'Lecture',
            updated: 'successfully updated',
            added: 'added successfully',
            deleted: 'deleted successfully',
            choose_course: 'Choose a course',
            lecture_edit: 'Edit lecture',
            lecture_add: 'Create lecture',
            no_lecture: 'No lecture'
        },
        tasks: {
            tasks: 'Task',
            create_task: 'Create Task',
            rated: 'Rated',
            assigned: 'Assigned',
            passed: 'Passed',
            deadline: 'Deadline',
            send_ratings: 'Send Ratings',
            rate: 'Rate:',
            sure_delete: 'Are you sure you want to delete this task?',
            deleted: 'deleted successfully',
            rated_success: 'Rated successfully',
            task_name: 'Task Name',
            task_desc: 'Task Description',
            for_whom: 'For whom:',
            rate_points: 'Set the maximum number of points from 1 to 100',
            points: 'Points:',
            successfully_deleted: 'successfully updated',
            successfully_added: 'successfully added',
            add_lecture: 'Add assignment to lecture',
            edit_task: 'Edit Task',
            add_task: 'Create task',
            no_lecture: 'Are you sure you want to send an assignment without linking to a lecture?',
            sure_change: 'Are you sure you want to make changes?',
            deadline_end: 'Deadline has ended',
            answer: 'Answer',
            link_to_answer: 'Repository link',
            answer_before: 'Deadline for response:',
            task_expired: 'Homework has expired',
            answer_rate: 'Congratulations! Your answer has been verified!',
            point: 'Score:',
            no_task: 'Teacher has not added a task yet.',
            go_to: 'If there are questions - go to',
            all_tasks: 'All Tasks',
            empty_tasks: 'The list of tasks is empty.'
        },
        chat: {
            lecturer_chat: 'Chat with the lecturer',
            start_send: 'Start correspondence',
            you: 'You',
            chat: 'Chat',
            empty_list: 'The list of students in the selected lecture is empty.',
            choose_lecture: 'Choose lecture and student',
            lecture_need: 'You need to add lectures to use the chat.'
        },
        declination: {
            one_student: 'student',
            two_student: 'students',
            lot_students: 'students',
            now: 'right now',
            seconds: 'sec.',
            minutes: 'min.',
            one_hour: 'one hour',
            two_hours: 'hours',
            lot_hours: 'hours',
            one_day: 'day',
            two_days: 'days',
            lot_days: 'days',
            one_month: 'month',
            two_months: 'months',
            lot_months: 'months',
            one_year: 'year',
            two_year: 'years',
            lot_year: 'years',
            ago: 'ago'
        },
        notifications: {
            title: 'Notifications',
            remove_all: 'Remove all',
            follow_the_link: 'Follow the link',
            empty: 'There are no new notifications.',
        }
    },
    ua: {
      buttons: {
        cancel: 'Скасувати',
        nix: 'Відмінити',
        confirm: 'Зберегти',
        yes: 'Так',
        no: 'Ні',
        warning: 'Попередження',
        delete: 'Вилучити',
        clear: 'Видалити',
        yes_delete: 'Так, видалити',
        add: 'Створити',
        all_students: 'Усі студенти',
        to_all_students: 'Всім студентам',
        choose_students: 'Вибрати студентів',
        students: 'Студенти',
        edit: 'Редагувати',
        added: 'Створено',
        published: 'Опубліковано',
        save: 'Зберегти',
        post: 'Опублікувати',
        post_changes: 'Опублікувати зміни',
        detail: 'Докладніше',
        date: 'Дата:',
        time: 'Час:',
        send: 'Надіслати',
        choose_file: 'Виберіть файл',
        enter: 'Натисніть Enter, щоб надіслати повідомлення',
        file: 'Файл',
        loading: 'Завантаження...',
        photo_edit: 'Редагування фото',
        choose_photo: 'Виберіти фото',
        close: 'Закрити',
        create: 'Додати'
      },
      message: {
        successfully_changed: 'успішно змінено',
        successfully_deleted: 'успішно видален',
        successfully_updated: 'успішно оновлений',
        no_notification: 'Нових оповіщень немає.',
        empty_chat: 'Повідомлень немає.',
        save_data: 'Дані збережено!',
        save_photo: 'Ваше фото зміненно!',
        pass_changed: 'Ваш пароль успішно зміненно!',
        answer_send: 'Ваша відповідь надіслана, очікуйте перевірку)',
      },
      errors: {
          empty_name: 'Не заповнена назва',
          empty_students: 'Не обрано жодного студента',
          empty_video: 'Не додано посилання на youtube',
          empty_course: 'Не обрано жодного курсу',
          incorrect_video: 'Некоректне посилання на youtube',
          empty_desc: 'Не заповнен опис',
          big_file: 'Завантажте файл меншого об\'єму (5 МБ або менше).',
          incorrect_format: 'Завантажте документ у допустимому форматі',
      },
      auth: {
          authorization: 'Авторизація',
          password: 'Пароль',
          forgot_password: 'Забули пароль?',
          registration: 'Реєстрація',
          enter: 'Увійти',
          entering: 'Вхід',
          email: 'E-mail',
          password_warning: 'має 8 і більше символів, має хоча б одну велику літеру, має хоча б\n' +
              '              одну малу літеру, матє хоча б одну цифру',
          password_confirmation: 'Підтвердіть пароль',
          name: 'Ім\'я',
          last_name: 'Прізвище',
          phone: 'Телефон',
          login: 'Вже є аккаунт? Увійти.',
          register: 'Зареєструватися',
          email_confirm: 'Підтвердження електронної пошти',
          resend: 'Надіслати лист ще раз',
          go_to_login: 'Перейти до авторизації',
          check: 'Перевіряємо...',
          resended: 'Лист з верифікацією надіслан повторно',
          thanks: 'Дякуємо за підтвердження електронної адреси.',
          password_restore: 'Відновлення пароля',
          new_password: 'Новий пароль',
          password_changed: 'Пароль успішно змінено!',
          password_change: 'Змінити пароль',
          password_forgot: 'Забули пароль?',
          will_send: 'Вам на пошту буде відправлене повідомлення з посиланням для зміни\n' +
              '          паролю',
          already_send: 'Ми надіслали ваше посилання для зміни пароля електронною\n' +
              '          поштою!',
          back_to_login: 'Повернутись до авторизації',
          send: 'Надіслати лист',
          confirm_email: 'Підтвердіть електронну пошту',
          please_confirm_email: 'Будь ласка, підтвердіть свою адресу електронної пошти. Для цього\n' +
              '            перейдіть за посиланням з листа, що вам було надіслано на пошту.',
          birthday: 'Дата народження',
          phone_number: 'Номер телефону',
          email_full: 'Електронна пошта',
          city: 'Місто',
          university: 'Університет',
          pass_year: 'Рік випуску',
          old_password: 'Старий пароль',
          confirm_new_pass: 'Підтвердження нового пароля',
          select_area: 'Виберати область',
          cancel: 'Скасувати',
          selected_file: 'Вибраний файл:',
      },
      index : {
          null_welcome: 'Ви успішно авторизувалися в системі!\nЗачекайте, невдовзі Вам нададуть доступ!',
          student_welcome: 'Вітаю студент. Невдовзі розпочнеться курс, очікуйте підключення від вашого викладача)',
          student_welcome_course: 'Вас зараховано до курсу ',
          lecturer_welcome: 'Вітаю викладач! Створіть свій перший курс)',
          find_by_name: 'Введіть ПІБ користувача для пошуку',
          find: 'Знайти',
          users: 'Користувачі',
          id: 'ID',
          name: 'Ім\'я',
          last_name: 'Прізвище',
          email: 'Електронна адреса',
          role: 'Роль',
          operation: 'Операціі',
          filter: 'Фільтр',
          all: 'Всі',
          delete_user: 'Вилучити цього користувача?',
          user_role: 'Роль користувача',
          user_delete: 'Користувач успішно видален',
          news: 'Новини',
          notification: 'Сповіщення',
          menu: 'Меню'
      },
      courses: {
          create_course: 'Створити курс',
          course_name: 'Назва курсу',
          add_users: 'Додати учасників',
          course: 'Курс',
          added: 'успішно додан',
          created: 'Створено:',
          users: 'Участники:',
          courses: 'Курси',
          delete_course: 'Видалити курс',
          lecturer: 'Викладач',
          add_students: 'Додати студента',
          student_name: 'Введіть им\'я студента',
          sure_delete_course: 'Видалити цей курс?',
          delete_student: 'Вилучити цього студента з курсу?'
      },
      lectures: {
          lectures: 'Лекції',
          add_lecture: 'Створити лекцію',
          lecture_name: 'Назва лекції',
          lecture_desc: 'Опис (необов\'язково)',
          url: 'Вставте URL-адресу відео',
          preview: 'Попередній перегляд',
          add_video: 'Додати відео',
          delete_lecture: 'Ви впевнені, що бажаєте видалити лекцію?',
          lecture: 'Лекція',
          updated: 'успішно оновлена',
          added: 'успішно додана',
          deleted: 'успішно видалена',
          choose_course: 'Вибрати курс',
          lecture_edit: 'Редагувати лекцію',
          lecture_add: 'Створення лекції',
          no_lecture: 'Без лекції'
      },
      tasks: {
          tasks: 'Завдання',
          create_task: 'Створити завдання',
          rated: 'Оцінено',
          assigned: 'Призначено',
          passed: 'Здали',
          deadline: 'Дедлайн',
          send_ratings: 'Надіслати оцінки',
          rate: 'Оцінити:',
          sure_delete: 'Ви впевнені що хочете віддалити це завдання?',
          deleted: 'успішно віддалено',
          rated_success: 'Оцінки успішно виставлені',
          task_name: 'Назва завдання',
          task_desc: 'Опис завдання',
          for_whom: 'Для кого:',
          rate_points: 'Встановіть максимально кількість балів від 1 до 100',
          points: 'Бали:',
          successfully_deleted: 'успішно оновлено',
          successfully_added: 'успішно додано',
          add_lecture: 'Додати завдання до лекції',
          edit_task: 'Редагувати завдання',
          add_task: 'Створення завдання',
          no_lecture: 'Ви впевнені що хочете надіслати завдання без прив\'язки до лекції?',
          sure_change: 'Ви впевнені що хочете внести зміни?',
          deadline_end: 'Дедлайн закінчився',
          answer: 'Відповідь',
          link_to_answer: 'Посилання на репозиторій',
          answer_before: 'Крайній термін надсилання відповіді:',
          task_expired: 'Термін виконання домашнього завдання закінчився',
          answer_rate: 'Вітаю! Твоя відповідь перевірена!',
          point: 'Оцінка:',
          no_task: 'Викладач ще не додав завдання.',
          go_to: 'Якщо є питання – перейдіть в',
          all_tasks: 'Усі завдання',
          empty_tasks: 'Список завдань порожній.'
      },
      chat: {
          lecturer_chat: 'Чат з викладачем',
          start_send: 'Почати листування',
          you: 'Ви',
          chat: 'Чат',
          empty_list: 'Список студентів у вибраної лекції порожній.',
          choose_lecture: 'Виберіть лекцію та студента',
          lecture_need: 'Для використання чату необхідно додати лекції.',
      },
      declination: {
          one_student: 'студент',
          two_student: 'студента',
          lot_students: 'студентів',
          now: 'прямо зараз',
          seconds: 'сек.',
          minutes: 'хв.',
          one_hour: 'годину',
          two_hours: 'години',
          lot_hours: 'годин',
          one_day: 'день',
          two_days: 'дні',
          lot_days: 'днів',
          one_month: 'місяць',
          two_months: 'місяці',
          lot_months: 'місяців',
          one_year: 'рік',
          two_year: 'роки',
          lot_year: 'років',
          ago: 'тому'
      },
      notifications: {
          title: 'Сповіщення',
          remove_all: 'Видалити всі1',
          follow_the_link: 'Перейти',
          empty: 'Нових оповіщень немає.',
      }
    }
};
