var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"container",attrs:{"role":"main"}},[_c('div',{staticClass:"col-md-12"},[_c('h2',[_vm._v(_vm._s(_vm.$t("tasks.all_tasks")))]),(_vm.tasksExist)?_c('ul',{staticClass:"student-task-list"},_vm._l((this.GET_TASK_ELEMENTS),function(task){return _c('li',{key:task.id,staticClass:"li4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 p-3"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(task.title))]),_c('p',[_vm._v(_vm._s(_vm.$t("buttons.published"))+": "+_vm._s(task.date))]),_c('p',[_vm._v(_vm._s(_vm.$t("lectures.lecture"))+": "+_vm._s(task.lecture.id ? task.lecture.title : _vm.$t("lectures.no_lecture")))])]),_c('div',{staticClass:"col-3"},[_c('p',[_vm._v(_vm._s(_vm.$t("tasks.deadline"))+":"),_c('br'),_vm._v(_vm._s(task.deadline))])]),_c('div',{staticClass:"col-3"},[(task.lecture.id > 0)?_c('RouterLink',{attrs:{"to":{
                                name: 'Task',
                                params: { lection_id: task.lecture.id }
                            }}},[_c('button',{staticClass:"btn btn-info"},[_vm._v(" "+_vm._s(_vm.$t("buttons.detail"))+" ")])]):_c('RouterLink',{attrs:{"to":{
                                name: 'StudentTask',
                                params: { task_id: task.id }
                            }}},[_c('button',{staticClass:"btn btn-info"},[_vm._v(" "+_vm._s(_vm.$t("buttons.detail"))+" ")])])],1)])])}),0):_c('div',[_vm._v(" "+_vm._s(_vm.$t("tasks.empty_tasks"))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }