var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3"},[_c('ul',{staticClass:"nav nav-link-secondary flex-column"},[_c('li',{staticClass:"nav-item flex"},[_c('RouterLink',{staticClass:"nav-link text-dark",attrs:{"to":{
          name: 'detail.lection',
          params: { lection_id: this.$route.params.lection_id }
        }}},[_vm._v(_vm._s(_vm.$t("lectures.lecture")))]),_c('RouterLink',{staticClass:"nav-link text-dark",attrs:{"to":{
            name: 'Task',
            params: { lection_id: this.$route.params.lection_id }
          }}},[_vm._v(_vm._s(_vm.$t("tasks.tasks")))]),_c('RouterLink',{staticClass:"nav-link text-dark",attrs:{"to":{
          name: 'Chat',
          params: { lection_id: this.$route.params.lection_id }
        }}},[_vm._v(_vm._s(_vm.$t("chat.lecturer_chat")))])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }